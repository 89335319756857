import React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from 'gatsby';
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../../scss/main.scss";
import BrochureRequestPanel from '../components/BrochureRequestPanel'
import CqcApproved from '../components/CqcApproved'

export default function RequestABrochure() {
  const { site } = useStaticQuery(
    graphql`
		  query {
        site {
          siteMetadata {
            appConfig {
              gtmContainerId
            }
          }
        }
		  }
		`
  )

  const { siteMetadata: {
    appConfig: {
      gtmContainerId
    }
  } } = site

  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Edyn Care | Request a brochure</title>
        <meta property="og:title" content="Edyn Care | Request a brochure" />
        <meta property="og:description" content="High quality care in the comfort of your own home." />
        <meta property="og:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
        <meta property="og:url" content="https://www.edyn.care/liveincare" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="edyn.care" />
        <meta property="twitter:url" content="https://www.edyn.care/liveincare" />
        <meta name="twitter:title" content="Edyn Care | Request a brochure" />
        <meta name="twitter:description" content="High quality care in the comfort of your own home." />
        <meta name="twitter:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
      </Helmet>

      <div className="container">
        <Header />
        <main >
          <section>
            <BrochureRequestPanel
              gtmContainerId={gtmContainerId}
              quoteImage={(
                <StaticImage placeholder="blurred"
                  src="../../static/assets/marketing-site/images/banner-brochure-img.png"
                  objectFit='contain'
                  height={86}
                />
              )}
              quoteText={`
                  Care homes used to be the default for individuals who are in
                  need of care, but now 99% of those in their later years can
                  stay at home with regulated live-in care. Edyn’s service is
                  changing care
                `}
              clientDescription={(
                <>
                  <p>Jai Smith</p>
                  <p>Dementia live-care</p>
                </>
              )}
            />
          </section>
          <section>
            <CqcApproved />
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
}
